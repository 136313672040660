/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import axios from 'axios';
import { NODE_API_URL,BASE_URL } from "../../../env_config";
import Cookies from "js-cookie"
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { toAbsoluteUrl } from "../../_helpers";
// import {
//     FaCalendarAlt,
//     FaUserCircle,
//     FaUndo,
//     FaBookOpen,
//     FaCopy,
// } from "react-icons/fa";
// import { GiCutDiamond } from "react-icons/gi";
// import DiamJournyStep from "./DiamJournyStep";
// function numberWithCommas(x) {
//     return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// }

export const ExpandedDetails = (data) => {
    const [expandData, setData] = React.useState(data.data);
    // console.log(expandData.diamondType)
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const PrintPerforma = () => {
        if(data.data.perfoma_no){
            let confirmgoodids = []
        for(let i = 0; i < data.proformadata.length;i++){
            if(data.data.perfoma_no === data.proformadata[i].perfoma_no){
                confirmgoodids.push(data.proformadata[i].conformGoodId)
            }
        }
        confirmgoodids = confirmgoodids.sort().toString()
            const userData = JSON.parse(localStorage.getItem("userData"))
            const finalData = {
                "id":data.data.perfoma_no,
                "conform_good_id":confirmgoodids
            }
            axios({
                method: "get",
                url: `${NODE_API_URL}/AUS/proforma-detail-page`,
                headers: {
                    "Content-Type": "application/json",
                     "Authorization": `Bearer ${Cookies.get("cust_auth_token")}` 
                },
                params: finalData,
            }).then(res => {
                if(res && res.data){
                    if(process.env.REACT_APP_ENV === "production"){
                        window.open(`https://assets.diamondsoncall.com/assets/pdf_invoice/${res.data.invoicelink}`, "PRINT")
                    }
                    else{
                    window.open(`https://alpha.diamondsupload.com/assets/pdf_invoice/${res.data.invoicelink}`, "PRINT")
            
                    }
                }
            }).catch(error => {
                if(error?.response?.data?.message === "UnAuthorized!"){
                  var cookies = document.cookie.split(";");
                for (var i = 0; i < cookies.length; i++) {
                  if(cookies[i] && !cookies[i].includes("view")){
                    var equals = cookies[i].indexOf("=");
                    var name = equals > -1 ? cookies[i].substr(0, equals) : cookies[i];
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                  }
                }
                
                window.location.replace(`${BASE_URL}/logout`);
              }
              })
        }
    }
    return (
        <div className="bg-gray-300 confirmExpand p-5 border">
            <div className="bg-white p-5 border">
                <div className="row border-bottom">
                    <div className="col-md-6 d-flex align-items-center flex-wrap">
                        <span className="font-size-h2 font-weight-bolder d-flex align-items-center">
                            #{expandData.conformGoodId}
                            <div
                                className={`label ${ expandData.diamondStatus === "Order Approve" ? "label-primary" 
                                            : expandData.diamondStatus === "Pending" ? "label-warning" 
                                            : expandData.diamondStatus === "Ready For Invoice" ? "label-success" 
                                            : expandData.diamondStatus === "Ready to Ship" ? "label-success"
                                            : expandData.diamondStatus === "Rejected" ? "label-danger"
                                            : expandData.diamondStatus === "QC Rejected" ? "label-danger"
                                            : expandData.diamondStatus === "QC Review" ? "label-warning"
                                            : expandData.diamondStatus === "QC Approved" ? "label-success"
                                            : ""
                                        } label-inline ml-2`}
                                >
                                {expandData.diamondStatus}
                            </div>
                            {expandData.proformaNo ? <span className="label label-primary mx-2 cursor-pointer label-inline" onClick={() => PrintPerforma()}>Print Proforma</span>: ""} 
                            {/* {expandData.proformaNo ? <a href="#" className="label label-light-info label-inline label-sm small ml-2" > Proforma generated </a>: ""}  */}
                            {expandData.proformaNo ? <a onClick={() => data.RouteToProforma({query:{perfoma_no:expandData.proformaNo,conform_good_id:expandData.conformGoodId}},data.proformadata)} className="label label-light-info label-inline ">Pro Forma Inv No {expandData.perfoma_created_at}</a> : ""}
                        </span>
                        <br />
                        <div className="text-muted small col-12 p-0">
                            Order Date: {expandData.created_date}
                            {/* <FaBookOpen className="ml-1" />{" "}
                            <span className="text-primary font-font-weight-bolder cursor-pointer ml-1">
                                Read all comments
                            </span> */}
                        </div>
                        <div className="text-muted small col-12 p-0">
                            Order Source: {expandData.order_source}
                            {/* <FaBookOpen className="ml-1" />{" "}
                            <span className="text-primary font-font-weight-bolder cursor-pointer ml-1">
                                Read all comments
                            </span> */}
                        </div>
                    </div>
                    <div className="text-md-right small mb-3 col-md-6">
                        {expandData.customerComment && expandData.customerComment !== "null" ? <div> <span className="font-weight-bold">Buy Comment :</span >{expandData.customerComment}</div>: null}
                        {expandData.holdComment && expandData.holdComment !== "null" ? <div> <span className="font-weight-bold">Hold Comment :</span >{expandData.holdComment}</div>: null}
                        {expandData.clientRef && expandData.clientRef !== "null" ? <div> <span className="font-weight-bold">Client Reference :</span >{expandData.clientRef}</div>: null}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-left">DIAMOND DETAILS</th>
                                    {/* <th className="text-center">TAG</th> */}
                                    <th className="text-center"> LOCATION <br /> DELIVERY IN DAYS</th>
                                    <th className="text-center">DISC</th>
                                    {/* <th className="text-center">INR RATE</th> */}
                                    <th align="center" className="text-right pr-8">
                                        Our Price(USD) <br />
                                        USD/CT
                                    </th>
                                    <th align="center" className="text-right pr-8">
                                        Our Price(AUD) <br />
                                        AUD/CT
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center">
                                    {expandData.diamondType === "PN" || expandData.diamondType === "PL" ? 
                                        <div className=" text-left">
                                            <div className={`mb-1 ${expandData.diamondType === "PN"? "natTab": "labTab"}`}>
                                                <span className={`${expandData.diamondType}_diamond mr-3 small`} >
                                                    {expandData.diamondType}
                                                </span>
                                                {/* <span className="text-primary small">{expandData.lab}-{expandData.certificate} </span> */}
                                                <span className={`text-primary small ml-2`}>
                                                    {expandData.diamondType}-{expandData.stockID}
                                                </span>
                                                <span className={`text-primary text-capitalize small ml-2`}>
                                                By: {expandData.parcelType}
                                                </span>
                                                <span className={`text-primary text-capitalize small ml-2`}>
                                                {expandData.parcelLength?expandData.parcelLength.toFixed(2):"" }-{expandData.parcelWidth?expandData.parcelWidth.toFixed(2):"" } MM
                                                </span>
                                            </div>

                                            <span className="text-dark font-weight-bold d-block">
                                            {expandData.shape}{" "}
                                            {expandData.weight?expandData.weight.toFixed(2):""}Ct.{" "}
                                            {expandData.parcelPieces}Pcs.{" "}
                                            {expandData.color}{" "}
                                            {expandData.clarity}{" "}
                                            </span>
                                        </div>
                                    : 
                                        <div className=" text-left">
                                            <div className={`mb-1 ${expandData.diamondType === "N"? "natTab": "labTab"}`}>
                                                <span className={`${ expandData.diamondType === "N" ? expandData.isfancy ? "F" : "W" : expandData.isfancy ? "LF": "L"}_diamond mr-3 small`} >
                                                    {expandData.diamondType === "N" ? expandData.isfancy ? "F" : "W" : expandData.isfancy ? "LF" : "L"}
                                                </span>
                                                <span className="text-primary small">{expandData.lab}-{expandData.certificate}
                                                </span>
                                                <span className={`text-primary small ml-2`}>
                                                    {expandData.diamondType === "N" ? "N" : "L"}-{expandData.stockID}
                                                </span>
                                            </div>

                                            <span className="text-dark font-weight-bold d-block">
                                                {expandData.shape}{" "}
                                                {expandData.weight}{" "}
                                                {expandData.color}
                                                {expandData.clarity}{" "}
                                                {expandData.cut}{" "}
                                                {expandData.polish}{" "}
                                                {expandData.symmetry}{" "}
                                                {expandData.fluorescence}
                                            </span>
                                        </div>
                                    }
                                        
                                    </td>
                                    {/* <td className="text-center">
                                        <div className="label label-outline-primary label-pill label-inline bg-primary-o-20 mr-1">
                                            NO SHADE
                                        </div>
                                        <div className="label label-outline-success label-pill label-inline bg-success-o-20 mr-1">
                                            EYECLEAN
                                        </div>
                                        <div className="label label-outline-info label-pill label-inline bg-info-o-20 mr-1">
                                            MILKY
                                        </div>
                                    </td> */}
                                    <td className="text-center"> {expandData.country} <br /> {expandData.shippingdays ? expandData.shippingdays : "7"} days</td>
                                    <td className={`text-center ${(expandData.discount >= 0) ? 'text-success' : 'text-danger'}`}>
                                        {expandData.diamondType === "PN" || expandData.diamondType === "PL" ? "-" :
                                            <span className={`${(expandData.discount >= 0) ? 'text-success' : 'text-danger'}`}>{Number(expandData.discount).toFixed(2)}%</span>
                                        }
                                    </td>
                                    {/* <td className="text-center">{expandData.cur_inr ? parseFloat(expandData.cur_inr).toFixed(2) : ""}</td> */}
                                    <td className="text-right">
                                        <span className="font-weight-bold text-primary">  ${numberWithCommas(parseFloat(expandData["ourPrice"]))}</span><br />
                                        <span className="text-muted">$/CT {numberWithCommas(parseFloat(expandData["ourRate"]))}</span>
                                    </td>
                                    <td className="text-right">
                                        <span className="font-weight-bold text-primary"> ${expandData["ourPriceExchange"]}</span > <br />
                                        <span className="text-muted"> $/CT {expandData["ourRateExchange"]}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
